import { createI18n } from "vue-i18n";
import messages from "@intlify/vite-plugin-vue-i18n/messages";
import { surveyLocalization } from "survey-core";
import { localization } from "@tekab-dev-team/survey-creator-knockout";

import "survey-core/survey.i18n.js";
import "survey-creator-core/survey-creator-core.i18n.js";
import en from "./en.json";
import fr from "./fr.json";
const i18n = createI18n({
  legacy: false,
  locale: "fr",
  globalInjection: true,
  fallbackLocale: "fr",
  messages,
});

export const setLanguageToFR = () => {
  const translations = localization.getLocale("fr");

  surveyLocalization.currentLocale = "fr";
  translations.ed.designer = "Modifier";
  translations.ed.testSurvey = "Aperçu";
  translations.ed.pageTypeName = "Question";
  translations.ed.newPageName = "Question";
  translations.p.pages = "Listes des questions";
  translations.pe.tabs.pages = "Listes des questions";
  translations.pe.maxTimeToFinishPage =
    "Temps minimum pour terminer la question (en secondes)";
  translations.pe.maxTimeToFinish =
    "Temps maximum pour terminer le questionnaire (en secondes)";
  translations.p.HasIntroduction = "Ajouter une introduction";
  translations.p.HasConclusion = "Ajouter une conclusion";
  translations.pe.tabs.choices = "Champs de réponse";
  translations.ed.addNewQuestion = "Ajouter une question";
  translations.pe.isRequired = "Réponses obligatoires";
  translations.p.pages = "Liste des questions";
  translations.p.placeholder = "Espace réservé";
  translations.pe.addNew = "Ajouter un nouvel élément";
  translations.pe.gotoName = "Rediriger vers";
  translations.p.triggers = "Conditionnelles";
  translations.pe.expression = "Condition";
  translations.pe.conditionHelp =
    "Si la condition indiquée est remplie, le répondant sera redirigé à la fin du questionnaire";
  translations.op.equal = "est égal";
  translations.op.greater = "est supérieur";
  translations.op.less = "est inférieur";
  translations.op.greaterorequal = "est supérieur ou égal";
  translations.op.lessorequal = "est inférieur ou égal";
  translations.p.choices = "Liste des réponses";
  translations.p.cols = "Nombre de colonnes";
  translations.pe.items = "Liste des champs";
  translations.pe.tabs.logo = "Ajouter un logo";
  translations.pe.if = "Si";
  translations.ed.surveyPlaceHolder =
    "Le sondage ne contient aucun élément. Faites glisser un élément depuis la liste des questions ou cliquez sur le bouton ci-dessous pour commencer à programmer votre questionnaire.";
  translations.pe.addNone = "Aucune de ces propositions";
  translations.pe.apply = "Enregistrer";
  translations.ed.newTextItemName = "Icones";
  translations.pe.tabs.navigation = "Options de navigation";
  translations.pe.tabs.timer = "Temps limite";
  translations.pe.logo = "Ajouter un logo";
  translations.survey.settings = "Options";
  translations.survey.duplicate = "Dupliquer la question";
  translations.pe.visible = "Masquer la question";
  translations.pe.addNone = "Aucune de ces propositions";
  translations.pe.hasComment = "Demander des précisions";
  translations.pe.displayRateDescriptionsAsExtremeItems =
    "Afficher les emojis comme valeurs extrêmes";
  localization.currentLocale = "fr";

  surveyLocalization.locales["fr"] = {
    pagePrevText: "Précédent",
    pageNextText: "Suivant",
    completeText: "Terminer",
    previewText: "Aperçu",
    editText: "Modifier",
    startSurveyText: "Commencer",
    otherItemText: "Autre : précisez",
    noneItemText: "Aucune de ces propositions",
    selectAllItemText: "Tout sélectionner",
    progressText: "Page {0} sur {1}",
    panelDynamicProgressText: "Enregistrement {0} sur {1}",
    questionsProgressText: "{0}/{1} question(s) répondue(s)",
    emptySurvey:
      "Il n'y a ni page visible ni question visible dans ce questionnaire",
    completingSurvey: "Merci d'avoir répondu au questionnaire !",
    completingSurveyBefore:
      "Nos données indiquent que vous avez déjà rempli ce questionnaire.",
    loadingSurvey: "Le questionnaire est en cours de chargement...",
    placeholder: "Choisissez parmi cette liste",
    ratingOptionsCaption: "Appuyez ici pour noter...",
    value: "valeur",
    requiredError: "La réponse à cette question est obligatoire.",
    requiredErrorInPanel: "Merci de répondre au moins à une question.",
    requiredInAllRowsError: "Toutes les lignes sont obligatoires",
    numericError: "La réponse doit être un nombre.",
    textMinLength: "Merci de saisir au moins {0} caractères.",
    textMaxLength: "Merci de saisir moins de {0} caractères.",
    textMinMaxLength: "Merci de saisir entre {0} et {1} caractères.",
    minRowCountError: "Merci de compléter au moins {0} lignes.",
    minSelectError: "Merci de sélectionner au minimum {0} réponses.",
    maxSelectError: "Merci de sélectionner au maximum {0} réponses.",
    numericMinMax:
      "Votre réponse '{0}' doit être supérieure ou égale à {1} et inférieure ou égale à {2}",
    numericMin: "Votre réponse '{0}' doit être supérieure ou égale à {1}",
    numericMax: "Votre réponse '{0}' doit être inférieure ou égale à {1}",
    invalidEmail: "Merci d'entrer une adresse mail valide.",
    invalidExpression: "L'expression: {0} doit retourner 'true'.",
    urlRequestError: "La requête a renvoyé une erreur '{0}'. {1}",
    urlGetChoicesError:
      "La requête a renvoyé des données vides ou la propriété 'path' est incorrecte",
    exceedMaxSize: "La taille du fichier ne doit pas excéder {0}.",
    otherRequiredError: "Merci de préciser le champ 'Autre'.",
    uploadingFile:
      "Votre fichier est en cours de chargement. Merci d'attendre quelques secondes et de réessayer.",
    loadingFile: "Chargement...",
    chooseFile: "Ajouter des fichiers...",
    fileDragAreaPlaceholder:
      "Déposez un fichier ici ou cliquez sur le bouton ci-dessous pour charger le fichier.",
    noFileChosen: "Aucun fichier ajouté",
    confirmDelete: "Voulez-vous supprimer cet enregistrement ?",
    keyDuplicationError: "Cette valeur doit être unique.",
    addColumn: "Ajouter une colonne",
    addRow: "Ajouter une ligne",
    removeRow: "Supprimer",
    addPanel: "Ajouter",
    removePanel: "Supprimer",
    choices_Item: "Proposition ",
    matrix_column: "Colonne",
    matrix_row: "Ligne",
    savingData: "Les résultats sont en cours de sauvegarde sur le serveur...",
    savingDataError:
      "Une erreur est survenue et a empêché la sauvegarde des résultats.",
    savingDataSuccess: "Les résultats ont bien été enregistrés !",
    saveAgainButton: "Réessayer",
    timerMin: "min",
    timerSec: "sec",
    timerSpentAll: "Vous avez passé {0} sur cette page et {1} au total.",
    timerSpentPage: "Vous avez passé {0} sur cette page.",
    timerSpentSurvey: "Vous avez passé {0} au total.",
    timerLimitAll:
      "Vous avez passé {0} sur {1} sur cette page et {2} sur {3} au total.",
    timerLimitPage: "Vous avez passé {0} sur {1} sur cette page.",
    timerLimitSurvey: "Vous avez passé {0} sur {1} au total.",
    cleanCaption: "Nettoyer",
    clearCaption: "Vider",
    chooseFileCaption: "Ajouter un fichier",
    removeFileCaption: "Enlever ce fichier",
    booleanCheckedLabel: "Oui",
    booleanUncheckedLabel: "Non",
    confirmRemoveFile:
      "Êtes-vous certains de vouloir supprimer ce fichier : {0}?",
    confirmRemoveAllFiles:
      "Êtes-vous certains de vouloir supprimer tous les fichiers?",
    questionTitlePatternText: "Titre de la question",
    emptyRowsText: "Il n'y a pas de lignes.",
    filteredTextPlaceholder: "Tapez pour rechercher...",
    indexText: "{0} sur {1}",
    minError: "La valeur ne doit pas être inférieure à {0}",
    maxError: "La valeur ne doit pas être supérieure à {0}",
    modalApplyButtonText: "Enregistrer",
    modalCancelButtonText: "Annuler",
    multipletext_itemname: "texte",
    noEntriesText:
      "Il n'y a pas encore d'entrées.\nCliquez sur le bouton ci-dessous pour ajouter une nouvelle entrée.",
    signaturePlaceHolder: "Signez ici",
  };
};
/**
 * Builds up valid keypaths for translations.
 * Update to your default locale of choice if not English.
 */
type DefaultLocale = typeof en;
export type TxKeyPath = RecursiveKeyOf<DefaultLocale>;

type RecursiveKeyOf<TObj extends Record<string, any>> = {
  [TKey in keyof TObj & string]: TObj[TKey] extends Record<string, any>
    ? `${TKey}` | `${TKey}.${RecursiveKeyOf<TObj[TKey]>}`
    : `${TKey}`;
}[keyof TObj & string];

export default i18n;
