import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/store/useAuth";
const dynamicRoutes = [
  {
    path: "/test",
    name: "test",
    meta: {
      requiresAuth: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "layout" */ "@/components/layouts/mainLayout/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "",
        component: () =>
          import(/* webpackChunkName: "list-appConfig" */ "@/views/Test.vue"),
      },
    ],
  },
  {
    path: "/appconfigs",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "layout" */ "@/components/layouts/mainLayout/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "list-appconfig",
        component: () =>
          import(
            /* webpackChunkName: "list-appConfig" */ "@/views/main/appConfig/AppConfigList.vue"
          ),
      },
      {
        path: "create",
        name: "create-appconfig",
        component: () =>
          import(
            /* webpackChunkName: "create-appConfig" */ "@/views/main/appConfig/AppConfigCreate.vue"
          ),
      },
      {
        path: "edit/:id",
        name: "edit-appconfig",
        component: () =>
          import(
            /* webpackChunkName: "edit-appConfig" */ "@/views/main/appConfig/AppConfigEdit.vue"
          ),
      },
      {
        path: ":id",
        name: "detail-appconfig",
        component: () =>
          import(
            /* webpackChunkName: "detail-appConfig" */ "@/views/main/appConfig/AppConfigDetail.vue"
          ),
      },
    ],
  },
  {
    path: "/workspaces",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "layout" */ "@/components/layouts/mainLayout/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "list-workspace",
        component: () =>
          import(
            /* webpackChunkName: "list-workspace" */ "@/views/main/workspace/WorkspaceList.vue"
          ),
      },
      {
        path: "create",
        name: "create-workspace",
        component: () =>
          import(
            /* webpackChunkName: "create-workspace" */ "@/views/main/workspace/WorkspaceCreate.vue"
          ),
      },
      {
        path: "edit/:id",
        name: "edit-workspace",
        component: () =>
          import(
            /* webpackChunkName: "edit-workspace" */ "@/views/main/workspace/WorkspaceEdit.vue"
          ),
      },
      {
        path: ":id",
        name: "detail-workspace",
        component: () =>
          import(
            /* webpackChunkName: "detail-workspace" */ "@/views/main/workspace/WorkspaceDetail.vue"
          ),
      },
    ],
  },
  {
    path: "/clients",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "layout" */ "@/components/layouts/mainLayout/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "list-client",
        component: () =>
          import(
            /* webpackChunkName: "list-client" */ "@/views/main/client/ClientList.vue"
          ),
      },
      {
        path: "create",
        name: "create-client",
        component: () =>
          import(
            /* webpackChunkName: "create-client" */ "@/views/main/client/ClientCreate.vue"
          ),
      },
      {
        path: "edit/:id",
        name: "edit-client",
        component: () =>
          import(
            /* webpackChunkName: "edit-client" */ "@/views/main/client/ClientEdit.vue"
          ),
      },
      {
        path: ":id",
        name: "detail-client",
        component: () =>
          import(
            /* webpackChunkName: "detail-client" */ "@/views/main/client/ClientDetail.vue"
          ),
      },
    ],
  },
  {
    path: "/workspacesteps",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "layout" */ "@/components/layouts/mainLayout/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "list-workspacestep",
        component: () =>
          import(
            /* webpackChunkName: "list-workspaceStep" */ "@/views/main/workspaceStep/WorkspaceStepList.vue"
          ),
      },
      {
        path: "create",
        name: "create-workspacestep",
        component: () =>
          import(
            /* webpackChunkName: "create-workspaceStep" */ "@/views/main/workspaceStep/WorkspaceStepCreate.vue"
          ),
      },
      {
        path: "edit/:id",
        name: "edit-workspacestep",
        component: () =>
          import(
            /* webpackChunkName: "edit-workspaceStep" */ "@/views/main/workspaceStep/WorkspaceStepEdit.vue"
          ),
      },
      {
        path: ":id",
        name: "detail-workspacestep",
        component: () =>
          import(
            /* webpackChunkName: "detail-workspaceStep" */ "@/views/main/workspaceStep/WorkspaceStepDetail.vue"
          ),
      },
    ],
  },
  {
    path: "/workspaceversions",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "layout" */ "@/components/layouts/mainLayout/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "list-workspaceversion",
        component: () =>
          import(
            /* webpackChunkName: "list-workspaceVersion" */ "@/views/main/workspaceVersion/WorkspaceVersionList.vue"
          ),
      },
      {
        path: "create",
        name: "create-workspaceversion",
        component: () =>
          import(
            /* webpackChunkName: "create-workspaceVersion" */ "@/views/main/workspaceVersion/WorkspaceVersionCreate.vue"
          ),
      },
      {
        path: "edit/:id",
        name: "edit-workspaceversion",
        component: () =>
          import(
            /* webpackChunkName: "edit-workspaceVersion" */ "@/views/main/workspaceVersion/WorkspaceVersionEdit.vue"
          ),
      },
      {
        path: ":id",
        name: "detail-workspaceversion",
        component: () =>
          import(
            /* webpackChunkName: "detail-workspaceVersion" */ "@/views/main/workspaceVersion/WorkspaceVersionDetail.vue"
          ),
      },
    ],
  },
  {
    path: "/workspaceresponses",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "layout" */ "@/components/layouts/mainLayout/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "list-workspaceresponse",
        component: () =>
          import(
            /* webpackChunkName: "list-workspaceResponse" */ "@/views/main/workspaceResponse/WorkspaceResponseList.vue"
          ),
      },
      {
        path: "create",
        name: "create-workspaceresponse",
        component: () =>
          import(
            /* webpackChunkName: "create-workspaceResponse" */ "@/views/main/workspaceResponse/WorkspaceResponseCreate.vue"
          ),
      },
      {
        path: "actions/edit/:id",
        name: "edit-workspaceresponse",
        component: () =>
          import(
            /* webpackChunkName: "edit-workspaceResponse" */ "@/views/main/workspaceResponse/WorkspaceResponseEdit.vue"
          ),
      },
      {
        path: ":id",
        name: "detail-workspaceresponse",
        component: () =>
          import(
            /* webpackChunkName: "detail-workspaceResponse" */ "@/views/main/workspaceResponse/WorkspaceResponseDetail.vue"
          ),
      },
    ],
  },
  {
    path: "/workspacegroups",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "layout" */ "@/components/layouts/mainLayout/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "list-workspacegroup",
        component: () =>
          import(
            /* webpackChunkName: "list-workspaceGroup" */ "@/views/main/workspaceGroup/WorkspaceGroupList.vue"
          ),
      },
      {
        path: "create",
        name: "create-workspacegroup",
        component: () =>
          import(
            /* webpackChunkName: "create-workspaceGroup" */ "@/views/main/workspaceGroup/WorkspaceGroupCreate.vue"
          ),
      },
      {
        path: "edit/:id",
        name: "edit-workspacegroup",
        component: () =>
          import(
            /* webpackChunkName: "edit-workspaceGroup" */ "@/views/main/workspaceGroup/WorkspaceGroupEdit.vue"
          ),
      },
      {
        path: ":id",
        name: "detail-workspacegroup",
        component: () =>
          import(
            /* webpackChunkName: "detail-workspaceGroup" */ "@/views/main/workspaceGroup/WorkspaceGroupDetail.vue"
          ),
      },
    ],
  },
  {
    path: "/activities",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "layout" */ "@/components/layouts/mainLayout/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "list-activity",
        component: () =>
          import(
            /* webpackChunkName: "list-activity" */ "@/views/main/activity/ActivityList.vue"
          ),
      },
      {
        path: "create",
        name: "create-activity",
        component: () =>
          import(
            /* webpackChunkName: "create-activity" */ "@/views/main/activity/ActivityCreate.vue"
          ),
      },
      {
        path: "edit/:id",
        name: "edit-activity",
        component: () =>
          import(
            /* webpackChunkName: "edit-activity" */ "@/views/main/activity/ActivityEdit.vue"
          ),
      },
      {
        path: ":id",
        name: "detail-activity",
        component: () =>
          import(
            /* webpackChunkName: "detail-activity" */ "@/views/main/activity/ActivityDetail.vue"
          ),
      },
    ],
  },
  {
    path: "/workspaceresponsesonclients",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "layout" */ "@/components/layouts/mainLayout/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "list-workspaceresponsesonclient",
        component: () =>
          import(
            /* webpackChunkName: "list-workspaceResponsesOnClient" */ "@/views/main/workspaceResponsesOnClient/WorkspaceResponsesOnClientList.vue"
          ),
      },
      {
        path: "create",
        name: "create-workspaceresponsesonclient",
        component: () =>
          import(
            /* webpackChunkName: "create-workspaceResponsesOnClient" */ "@/views/main/workspaceResponsesOnClient/WorkspaceResponsesOnClientCreate.vue"
          ),
      },
      {
        path: "edit/:id",
        name: "edit-workspaceresponsesonclient",
        component: () =>
          import(
            /* webpackChunkName: "edit-workspaceResponsesOnClient" */ "@/views/main/workspaceResponsesOnClient/WorkspaceResponsesOnClientEdit.vue"
          ),
      },
      {
        path: ":id",
        name: "detail-workspaceresponsesonclient",
        component: () =>
          import(
            /* webpackChunkName: "detail-workspaceResponsesOnClient" */ "@/views/main/workspaceResponsesOnClient/WorkspaceResponsesOnClientDetail.vue"
          ),
      },
    ],
  },
  {
    path: "/user-profile",
    name: "userProfile",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "layout" */ "@/components/layouts/mainLayout/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "user-profile",
        
        component: () =>
          import(
            /* webpackChunkName: "user-profile" */ "@/views/main/userProfile/MyProfile.vue"
          ),
      },
    ],
  },
];
const signature=[
  {
    path: "/signature",
    redirect: "/",
    meta: {
      requiresAuth: false,
    },

    component: () =>
      import(
        /* webpackChunkName: "auth_layout" */ "@/components/layouts/mainLayout/LayoutForSignature.vue"
      ),
    children: [
      {
        path: ":id/:numberOfContract/:token",
        name: "signature",
        meta: {
          requiresAuth: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "sign_up" */
            "@/views/InviteTosign.vue"
          ),
      }
    ],
  },
]

const staticRoutes = [
  {
    path: "/auth",
    redirect: "/",
    component: () =>
      import(
        /* webpackChunkName: "auth_layout" */ "@/components/layouts/Auth.vue"
      ),
    children: [
      {
        path: "sign-in",
        name: "sign-in",
        meta: {
          auth: true,
        },
        component: () =>
          import(/* webpackChunkName: "sign_in" */ "@/views/auth/SignIn.vue"),
      },
      {
        path: "sign-up",
        name: "sign-up",
        meta: {
          auth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "sign_up" */
            "@/views/auth/SignUp.vue"
          ),
      },
      {
        path: "invite-sign-up",
        name: "invite-sign-up",
        meta: {
          auth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "sign_up" */
            "@/views/auth/InviteSignUp.vue"
          ),
      },
      {
        path: "password-reset",
        name: "password-reset",
        meta: {
          auth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "password_reset" */ "@/views/auth/ResetPassword.vue"
          ),
      },
      {
        path: "email-reset-password",
        name: "email-reset-password",
        meta: {
          auth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "password_reset" */ "@/views/auth/EmailResetPassword.vue"
          ),
      },
      {
        path: "msg-reset-password",
        name: "msg-reset-password",
        meta: {
          auth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "password_reset" */ "@/views/auth/MsgResetPassword.vue"
          ),
      },
    ],
  },
  {
    path: "",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "layout" */ "@/components/layouts/mainLayout/Layout.vue"
      ),
    children: [
      {
        name: "home",
        path: "",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(/* webpackChunkName: "HomePage" */ "@/views/Home.vue"),
      },
      {
        path: "invite-sign/:id/:numberOfContract",
        name: "invite-sign",
        component: () =>
          import(
            /* webpackChunkName: "sign_up" */
            "@/views/InviteTosign.vue"
          ),
      },
      {
        path: "actions/edit/:id",
        name: "edit-response",
        component: () =>
          import(
            /* webpackChunkName: "edit-workspaceResponse" */ "@/views/main/workspaceResponse/WorkspaceResponseEdit.vue"
          ),
      },
      {
        path: "actions/admin/:id",
        name: "edit-response-admin",
        component: () =>
          import(
            /* webpackChunkName: "edit-workspaceResponse" */ "@/views/main/workspaceResponse/WorkspaceResponseEdit.vue"
          ),
      },
    ],
  },
  {
    // the 403 forbidden route,
    path: "/403",
    name: "403",
    component: () =>
      import(/* webpackChunkName: "error_403" */ "@/views/errors/Error403.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "error_404" */ "@/views/errors/Error404.vue"),
  },
  {
    path: "/doc",
    name: "doc",
    component: () =>
      import(/* webpackChunkName: "doc" */ "@/views/doc/doc.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
  {
    path: "/callback",
    name: "callback",
    meta: {
      auth: false,
    },
    component: () =>
      import(/* webpackChunkName: "callback" */ "@/components/Callback.vue"),
  },
];

const routes: Array<RouteRecordRaw> = [...staticRoutes, ...dynamicRoutes, ...signature];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const authRoute = to.matched.some((record) => record.meta.auth);
  const store = useAuthStore();

  const { currentUser, isLoggedIn } = storeToRefs(useAuthStore());
  currentUser.value ? undefined : await store.getCurrent();

  if (isLoggedIn.value && authRoute && !to.fullPath.includes("type=recovery")) {
    next({ name: "home" });
    return;
  }
  if (
    isLoggedIn.value &&
    from.fullPath.includes("actions/admin")
  ) {

    window.open(`${to.path}`, "_self");
    next();
    return;
  }

  if (requiresAuth && !isLoggedIn.value) {

    next({ name: "sign-in" });
    return;
  }
  if (
    isLoggedIn.value &&
    from.redirectedFrom?.fullPath.includes("actions/edit")
  ) {

     window.open(from.redirectedFrom.fullPath, "_self");
      next()
    return;
  }

  next();

  return;
});

export default router;
