import { defineStore } from "pinia";
import { authService } from "@/core/services/AuthService";
import service from "@/service";
//import { Provider } from "@supabase/supabase-js";

export const useAuthStore = defineStore("authStore", {
  state: () => {
    return {
      currentUser: null as any,
      accessToken: "" as string | undefined,
      refreshToken: "" as string,
      isLoggedIn: null as null | boolean,
    };
  },
  actions: {
    async login(email: string, password: string) {
      try {
        // const { session } = await authService.signInWithEmail(email, password);
        // this.isLoggedIn = true;
        // this.currentUser = session?.user;
        // this.accessToken = session?.access_token;
        // this.refreshToken = session?.refresh_token!;
        const data = await service.api.authControllerLogin(
          { username: email, password: password },
          { format: "json" }
        );
        this.isLoggedIn = true;
        this.accessToken = data.data.accessToken;
        this.currentUser = data.data;
        localStorage.setItem("token", this.accessToken);

        // this.refreshToken = ??
        service.setBaseApiParams({
          headers: {
            Authorization: "Bearer " + data.data.accessToken,
          },
        });
        return { error: null };
      } catch (error: any) {
        console.log(error?.message, "error login");
        return { error: error };
      }
    },
    /*async loginWithProvider(provider: Provider) {
      try {
        const { result } = await authService.signInWithProvider(provider);
        this.isLoggedIn = true;
        this.currentUser = result.session?.user;
        this.accessToken = result.session?.access_token;
        this.refreshToken = result.session?.refresh_token!;
        service.setBaseApiParams({
          headers: {
            Authorization: "Bearer " + result.session?.access_token,
          },
        });
        return { data: result.session, error: null };


      } catch (error: any) {
        console.log(error?.message, "error login");
        return { data: null, error: error };
      }
    },*/
    async signUp(
      email: string,
      password: string,
      firstName: string,
      lastName: string
    ) {
      try {
        const result = await authService.signUp(
          email,
          password,
          firstName,
          lastName
        );
        if (result.data) {
          this.isLoggedIn = true;
          this.accessToken = result?.data?.data?.accessToken;
          this.currentUser = result.data.data;
          localStorage.setItem("token", this.accessToken);
          //this.refreshToken = result?.data?.data?.refresh_token!;
          service.setBaseApiParams({
            headers: {
              Authorization: "Bearer " + result?.data?.data?.accessToken,
            },
          });
        }
        return { error: null };
      } catch (error) {
        console.log(error, "error signup");
        return { error: error };
      }
    },
    async resetByEmail(email: string) {
      await authService.resetByEmail(email);
    },
    async reset(token: string, newPassword: string) {
      await authService.reset(token, newPassword);
    },
    async logout() {
      await authService.signOut();
      localStorage.removeItem("token");
      this.currentUser = null;
      this.accessToken = "";
      this.refreshToken = "";
      this.isLoggedIn = false;
    },
    async getCurrent() {
      try {
        const localStorageToken = localStorage.getItem("token");
        if (localStorageToken && !this.isLoggedIn) {
          this.isLoggedIn = true;
          this.accessToken = localStorageToken;
          const res = await service.api.authControllerGetClientByToken(
            { token: localStorageToken },
            { format: "json" }
          );
          if (!res) {
            localStorage.removeItem("token");
            window.location.pathname = "/auth/sign-in";
          }
          if (res && res.hasOwnProperty("data")) {
            if (res.data.client !== undefined) {
              delete res.data.client.password;
              this.currentUser = res.data.client;
            } else {
              delete res.data.user.password;
              this.currentUser = res.data.user;
            }
            // update token
            this.accessToken = res.data.token;
            localStorage.setItem("token", res.data.token);
          }
          service.setBaseApiParams({
            headers: {
              Authorization: "Bearer " + this.accessToken,
            },
          });
        }
      } catch (error) {
        console.log(error);
      }

      /*const result = await authService.getCurrent();
      this.isLoggedIn = result.isLoggedIn;
      if (result.session) {
        this.currentUser = result.session.user;
        this.accessToken = result.session.access_token;
        this.refreshToken = result.session.refresh_token!;
      } else {
        this.currentUser = null;
        this.accessToken = "";
        this.refreshToken = "";
      }
      console.log("isLoggedIn ", this.isLoggedIn);
      return result;*/
      const localStorageToken = localStorage.getItem("token");
      if (localStorageToken && !this.isLoggedIn) {
        this.isLoggedIn = true;
        this.accessToken = localStorageToken;
        const res = await service.api.authControllerGetUserByToken(
          { token: localStorageToken },
          { format: "json" }
        );
        delete res.data.user.password;
        this.currentUser = res.data.user;
        // update token
        //  this.accessToken = res.data.token
        //  localStorage.setItem("token", res.data.token)
      }
    },

    // async getCurrent() {
    //   try {
    //     const result = await authService.getCurrentUser();
    //     if (result?.data) {
    //       this.isLoggedIn = result?.isLoggedIn;
    //       this.currentUser = result?.data;
    //       this.accessToken = result?.access_token;
    //       this.refreshToken = result?.refresh_token;
    //     } else {
    //       this.currentUser = null;
    //       this.accessToken = "";
    //       this.refreshToken = "";
    //       this.isLoggedIn = false;
    //     }
    //   } catch (error) {
    //     console.log(error, "error get current user");
    //   }
    // },

    async clientLogin(email: string, password: string) {
      try {
        // const { session } = await authService.signInWithEmail(email, password);
        // this.isLoggedIn = true;
        // this.currentUser = session?.user;
        // this.accessToken = session?.access_token;
        // this.refreshToken = session?.refresh_token!;
        const data = await service.api.authControllerClientLogin(
          { email: email, password: password },
          { format: "json" }
        );
        this.isLoggedIn = true;
        this.accessToken = data.data.accessToken;
        this.currentUser = data.data;
        localStorage.setItem("token", this.accessToken);

        // this.refreshToken = ??
        service.setBaseApiParams({
          headers: {
            Authorization: "Bearer " + data.data.accessToken,
          },
        });
        return { error: null };
      } catch (error: any) {
        console.log(error, "error login");
        return { error: error };
      }
    },
  },
});
